#skills {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Noto Sans,Ubuntu,'Droid Sans','Helvetica Neue',sans-serif;
    background-color: rgb(29, 29, 167);
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 850px;
    max-width: 1300px;
    height: 600px;
    margin: 100px auto;
}

.board-toolbar {
    color: white;
    font-size: 2rem;
    padding-left: 15px;
    display: flex;
}
.board-toolbar-title {
    display: inline-block;
}
.board-toolbar-buttons {
    flex-grow: 1;
    text-align: right;
}
.board-toolbar-icon {
    display: inline-block;
    padding-right: 10px;
}

.board-list {
    display: flex;
    border-radius: 5px;
    background-color: lightblue;
    margin: 5px;
    flex-grow: 1;
    overflow: auto;
}

.board {
    border-radius: 5px;
    width: 300px;
    margin: 5px 10px 5px 10px;
}

.board-header {
    font-size: 1.1rem;
    font-weight: bold;
    background-color: gray;
    padding: 3px 10px 3px 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.board-content {
    background-color: rgb(105, 137, 185);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0px 3px 15px 3px;
}

.board-tile {
    border: 1px solid black;
    border-radius: 5px;
    height: 3.5rem;
    min-width: 294px;
    background-color: rgb(133, 176, 240)
}

.board-tile:hover {
    cursor: pointer;
}

.board-tile-name {
    font-size: 1.2rem;
    font-weight: 500;
    padding-left: 5px;
}