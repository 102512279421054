.navbar {
    background-color: #333;
    position: fixed;
    top: 0;
    width: 100%;
    transition: top 0.3s;
    z-index: 2;
}

.hiding-nav {
    top: -50px;
}

.nav-icon {
    display: inline-block;
    float: left;
    margin-top: 3px;
    height: 3rem;
}

.navbar a {
    float: left;
    display: block;
    color: white;
    text-align: center;
    padding: 15px;
    text-decoration: none;
}

.navbar a:hover {
    background-color: #ddd;
    color: black;
}

.nav-button{
    float: left;
    display: block;
    color: white;
    background-color: #333;
    border: none;
    text-align: center;
    font-size: 1rem;
    padding: 15px;
    cursor: pointer;
}

.nav-button:hover {
    background-color: #ddd;
    color: black;
}

.nav-links {
    float: right;
}