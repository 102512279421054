#about {
    font-family: Consolas;
    min-width: 850px;
    max-width: 1300px;
    background-color: black;
    border-radius: 5px;
    margin: 100px auto;
}

#about h1 {
    color: #FFCC00;
}
#about p {
    color: #33FF00
}
#about p b {
    color: #b180d7;
}

.terminal-header {
    height: 30px;
    background: rgb(61, 51, 199);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    vertical-align: middle;
}

.terminal-title {
    font-size: 1.3rem;
    font-weight: bold;
    padding-left: 10px;
    margin-top: 0;
    display: inline-block;
}

.dot {
    height:20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10px;
    margin-top: 5px;
}

.close-dot {
    background-color: red;
}

.min-dot {
    background-color: yellow;
}

.max-dot {
    background-color: green;
}

.terminal-body {
    display: flex;
    padding-bottom: 15px;
}

.profile-pic {
    display: inline-block;
}

.profile-photo {
    height: 15rem;
    width: 15rem;
    display: block;
    background-image: url('/public/pixel.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin: 10px;
}

.about-text {
    display: inline-block;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.cursor {
    display: inline-block;
    background-color: #33FF00;
    height: 1rem;
    width: 0.75rem;
    vertical-align: bottom;
    animation: 1.5s linear infinite blink_effect;
}

@keyframes blink_effect {
    0% {
      visibility: hidden;
    }
    50% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
}