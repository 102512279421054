.skill-modal {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    top:0px;
    background-color: rgba(255,255,255,0.5);
}

.skill-modal-show {
    display: block;
}

.skill-modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 400px;
    border: 1px solid black;
    border-radius: 10px;
}

.skill-modal-header {
    padding: 5px;
    background-color: rgb(66, 145, 172);
    color:white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.skill-modal-title {
    font-size: 1.5rem;
    font-weight: bold;
}
.skill-modal-timeframe {
    font-style: italic;
}
.skill-modal-body {
    border-top: 1px solid black;
    padding: 5px 10px 5px 10px;
}
.skill-modal-footer {
    width: 100%;
    bottom: 0;
}

.skill-modal-footer * {
    float: right;
}

.skill-modal-close {
    margin: 5px;
    color: white;
    background-color: rgb(66, 145, 172);
    border-radius: 5px;
    font-size: 1rem;
    padding: 10px;
}

.skill-modal-close:hover {
    cursor: pointer;
}