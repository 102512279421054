#experience {
    display: flex;
    flex-direction: column;
    background-color: gray;
    border-radius: 5px;
    font-family: 'Courier New', Courier, monospace;
    min-width: 800px;
    max-width: 1000px;
    height: 500px;
    margin: 100px auto;
}

.notepad-header {
    display: flex;
    background-color: lightblue;
    font-size: 2rem;
    font-weight: bold;
    padding-left: 10px;
}
.notepad-header-controls {
    flex-grow: 1;
    text-align: end;
    vertical-align: middle;
    margin-right:5px;
}
.notepad-icon-wrapper {
    display: inline-block;
    height: 2rem;
    width: 2rem;
    background-color: blue;
    color: white;
    border: 1px solid white;
    border-radius: 3px;
    margin-top: 5px;
    margin-right: 2px;
}
.notepad-icon-wrapper-close {
    background-color: red;
}

.tab-list {
    background-color: gray;
}

.tab {
    display: inline-block;
    border: 1px solid black;
    padding-left: 5px;
    padding-right: 5px;
}

.tab:hover {
    cursor: pointer;
}

.tab-selected {
    background-color: lightgray;
}

.notepad-body {
    background: linear-gradient(90deg, lightgray 2.1rem, white 0%);
    margin: 0px 5px 5px 5px;
    flex-grow: 1;
    font-size: 1.2rem;
}

.notepad-body ol {
    margin-top: 0;
}