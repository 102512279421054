#projects {
    font-family: sans-serif;
    background-color: gray;
    height: 800px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    min-width: 1300px;
    max-width: 1300px;
    margin: 100px auto;
}
.browser-header-title {
    font-size: 1.3rem;
    margin-left: 10px;
}
.browser-tab-list {
    background-color: gray;
}
.browser-tab {
    cursor: pointer;
    display: inline-block;
    color: white;
    height: 2rem;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
}
.browser-tab-selected {
    background-color: rgb(70, 70, 70);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.browser-page {
    background-color: rgb(70, 70, 70);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.browser-navigation-bar {
    display: flex;
}
.browser-url-bar {
    flex-grow: 1;
    background-color: white;
    border-radius: 5px;
    margin: 5px 10px 5px 10px;
}
.browser-body {
    background-color: white;
    margin: 5px;
    border-radius: 10px;
    flex-grow: 1;
}